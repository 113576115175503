<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuova presenza</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Customer list, triggered with click on select client in form -->
                <Transition name="fade-transition">
                    <div v-show="showCustomer" class="customer_modal">
                        <div class="customers_container">
                            <input type="text" placeholder="Cerca cliente" v-model="searchQuery" class="search_customers" />
                            <div class="customers_list">
                                <div
                                    v-for="customer in searchedCustomers"
                                    :key="customer.customers_id"
                                    @click="setSelectedCustomer(customer)"
                                    class="customer"
                                    :class="setActiveCustomer(customer, selectedCustomer)"
                                >
                                    {{ customer.customers_company ? customer.customers_company : `${customer.customers_name} ${customer.customers_last_name}` }}
                                </div>
                            </div>
                        </div>
                        <div @click="closeCustomerSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <form @submit.prevent="creaPresenza()">
                    <ion-list class="fields">
                        <!-- Reparto -->
                        <ion-item v-if="dipendenti_dichiara_reparto === '1'" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Reparto
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="field">
                                    <ion-select
                                        name="tipologia"
                                        interface="action-sheet"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="repartoSelezionato"
                                        value="1"
                                        placeholder="Seleziona il reparto in cui timbrare"
                                        @ionChange="setReparto($event.target.value)"
                                    >
                                        <ion-select-option v-for="reparto in reparti" :key="reparto.reparti_id" :value="reparto.reparti_id">
                                            {{ reparto.reparti_nome }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data inizio -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_start"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2021"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="presenza.data_inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora inizio -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Ora inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="start_time"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        value="1990-02-19T07:43Z"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="presenza.ora_inizio"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data fine -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_start"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2021"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="presenza.data_fine"
                                        disabled
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora fine -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Ora fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="start_time"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        value="1990-02-19T07:43Z"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="presenza.ora_fine"
                                        placeholder="Seleziona ora di fine"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Note -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Note
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-textarea v-model="presenza.note" rows="4" placeholder="Informazioni presenza..."> </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding" style="display: none">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>
                                        Cliente
                                    </span>
                                    <div @click="openCustomerSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="selectedCustomer">
                                        {{
                                            selectedCustomer.customers_company
                                                ? selectedCustomer.customers_company
                                                : `${selectedCustomer.customers_name} ${selectedCustomer.customers_last_name}`
                                        }}
                                    </div>
                                    <div v-else>Nessun cliente selezionato</div>
                                </div>
                            </div>
                        </ion-item>

                        <div class="actions">
                            <button type="submit" class="btn_crea_presenza">
                                Crea presenza
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonText,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonIcon,
    actionSheetController,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";

import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";
import { useRouter } from "vue-router";

import moment from "moment";

import { openToast } from "../services/toast";

import apiClienti from "../services/clienti";
import apiPresenze from "../services/presenze";
import apiReparti from "../services/reparti";

export default defineComponent({
    name: "ModalCreaPresenza",
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonText,
        IonSelect,
        IonSelectOption,
        IonTextarea,
        IonIcon,
    },
    setup(props, context) {
        //console.log(props.data);

        const router = useRouter();

        const recall_date = new Date();

        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id; //set userID in user field
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;
        const user = JSON.parse(localStorage.getItem("userInfo"));
        //Get dipendente settings
        const { dipendenti_dichiara_reparto } = user;

        const data_viaggio = new Date();
        const start_hour = new Date();
        const end_hour = new Date();
        start_hour.setHours(start_hour.getHours() - 1);
        end_hour.setHours(end_hour.getHours() + 3);

        const presenza = reactive({
            cliente: "",
            data_inizio: data_viaggio.toISOString(),
            data_fine: data_viaggio.toISOString(),
            ora_inizio: start_hour.toISOString(),
            ora_fine: end_hour.toISOString(),
            note: "",
        });

        const successResponse = ref(false);

        /**
         * ! Gestione straordinario manuale e richiesta dati reparti
         */
        const reparti = ref([]);
        const repartoSelezionato = ref(null);
        async function loadReparti() {
            apiReparti
                .getUserReparti(dipendenteID)
                .then((response) => {
                    if (response.data.length != 0) {
                        reparti.value = response.data;
                    } else {
                        reparti.value = [];
                    }
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la richiesta dei reparti", "danger");
                });
        }
        loadReparti();

        /**
         * Setta il reparto, obbligatorio prima di inserire la presenza
         * @param reparto_id string of selected reparto
         */
        function setReparto(reparto_id) {
            repartoSelezionato.value = reparto_id;
        }

        /**
         * ! GESTIONE CLIENTE PRESENZA
         */

        const showCustomer = ref(false);
        function openCustomerSelection() {
            showCustomer.value = true;
        }
        function closeCustomerSelection() {
            showCustomer.value = false;
        }
        /**
         * !Load customer
         */
        const customers = ref([]);
        async function loadCustomer() {
            try {
                const res = await apiClienti.getCustomers();
                if (res.status === 0) {
                    customers.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei clienti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei clienti", "toast_danger");
            }
        }

        const searchQuery = ref("");
        const searchedCustomers = computed(() => {
            const term = searchQuery.value.replace(/ /g, "");

            return customers.value.filter((cliente) => {
                //company, nome/cognome, province
                if (cliente.customers_company) {
                    return (
                        cliente.customers_company
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                    );
                } else {
                    if (cliente.customers_name && cliente.customers_last_name) {
                        return (
                            cliente.customers_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            cliente.customers_last_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            (cliente.customers_last_name + cliente.customers_name)
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            (cliente.customers_name + cliente.customers_last_name)
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    } else if (cliente.customers_name && !cliente.customers_last_name) {
                        return (
                            cliente.customers_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    } else if (!cliente.customers_name && cliente.customers_last_name) {
                        return (
                            cliente.customers_last_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    }
                }
            });
        });

        const selectedCustomer = ref(null);
        function setSelectedCustomer(customer) {
            //console.log(customer);
            if (customer) {
                searchQuery.value = "";
                selectedCustomer.value = customer;
                showCustomer.value = false;
                //imposta il cliente sul rimborso
                presenza.cliente = customer.customers_id;
            }
        }

        const setActiveCustomer = computed(() => {
            return (customer, selectedCustomer) => {
                let className = "";

                if (selectedCustomer) {
                    if (customer.customers_id === selectedCustomer.customers_id) {
                        className = "active_customer";
                    }
                }
                return className;
            };
        });

        /**
         * ! Se data inizio > data fine aggiorno con data fine = data inizio
         * ! Al cambio di data _inizio aggiorna data_fine
         */
        watch(
            () => presenza.data_inizio,
            (date, prevDate) => {
                if (presenza.data_fine) {
                    if (moment(presenza.data_fine).isBefore(moment(presenza.data_inizio)) || moment(presenza.data_fine).isAfter(moment(presenza.data_inizio))) {
                        presenza.data_fine = date;
                    }
                }
            }
        );

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Create new spesa
         */
        async function creaPresenza() {
            //Data obbligatoria
            if (!presenza.data_inizio) {
                openToast("La data è obbligatoria", "toast_danger");
                return;
            }
            //controllo che ora inizio < ora fine
            if (!moment(presenza.ora_inizio).isBefore(moment(presenza.ora_fine))) {
                openToast("L'ora di inizio deve essere precedente all'ora di fine", "toast_danger");
                return;
            }
            //controllo che data non sia avanti rispetto ad oggi
            if (moment(presenza.data_inizio).isAfter(moment())) {
                openToast("Non puoi creare una presenza per una data futura", "toast_danger");
                return;
            }

            //Se devo comunicare prima il reparto controllo di averlo selezionato
            if (dipendenti_dichiara_reparto === "1" && !repartoSelezionato.value) {
                openToast("Prima di timbrare devi selezionare il reparto di riferimento", "toast_danger");
                return;
            }

            const data = new FormData();
            data.append("presenze_dipendente", dipendenteID); //logged user
            data.append("presenze_cliente", presenza.cliente); //cliente selezionato
            data.append("presenze_data_inizio", moment(presenza.data_inizio).format("YYYY-MM-DD")); //data entrata
            data.append("presenze_data_fine", moment(presenza.data_fine).format("YYYY-MM-DD")); //data uscita
            data.append("presenze_ora_inizio", moment(presenza.ora_inizio).format("HH:mm")); //ora entrata
            data.append("presenze_ora_fine", moment(presenza.ora_fine).format("HH:mm")); //ora uscita
            data.append("presenze_note", presenza.note); //note presenza
            data.append("presenze_reparto", repartoSelezionato.value); //reparto selezionato

            apiPresenze
                .creaPresenza(data)
                .then((response) => {
                    //console.log(response);
                    if (response.data.status !== 0) {
                        openToast(response.data.message, "toast_danger");
                    } else {
                        const res = response.data.data;
                        //console.log(res);
                        successResponse.value = true;
                        closeModalOnSubmit(successResponse, res[0]);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la creazione della presenza", "toast_danger");
                });
        }

        onMounted(() => {
            loadCustomer();
        });

        return {
            arrowBackOutline,
            presenza,
            creaPresenza,
            closeModal,
            customers,
            showCustomer,
            openCustomerSelection,
            closeCustomerSelection,
            searchQuery,
            searchedCustomers,
            setSelectedCustomer,
            selectedCustomer,
            setActiveCustomer,
            //Gestione selezione reparto
            dipendenti_dichiara_reparto,
            setReparto,
            reparti,
            repartoSelezionato,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.error_message {
    color: #eb445a;
}
.danger {
    color: #eb445a !important;
}

.example {
    border: 1px solid black;
}

.alert-wrapper {
    width: 90% !important;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}
.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --highlight-height: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}
ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    margin-top: 0;
}

/* .btn_crea_presenza {
      --background: #16a34a;
      --background-activated: #15803d;
      --background-hover: #15803d;
      --background-focused: #15803d;
      --box-shadow: none;
      --border-radius: 4px;
      --padding-top: 10px;
      --padding-bottom: 10px;
      --padding-start: 10px;
      --padding-end: 10px;
      --ripple-color: none;
      --color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
    } */
.btn_crea_presenza {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    text-align: center;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
}

/* .btn_foto {
      --background: #0284c7;
      --background-activated: #0369a1;
      --background-hover: #0369a1;
      --background-focused: #0369a1;
      --box-shadow: none;
      --border-radius: 4px;
      --padding-top: 10px;
      --padding-bottom: 10px;
      --padding-start: 10px;
      --padding-end: 10px;
      --ripple-color: none;
      --color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      margin-bottom: 16px;
    } */
.btn_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    background-color: #0a90d4;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
}

/* Image rules */
.img_photo {
    position: relative;
    max-height: 300px;
}
.img_action {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #dc2626;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
ion-button {
    --color: #ffffff;
}

/** Customer selection  */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_customers {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_customers::placeholder {
    color: #6b7280;
}
.search_customers:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.customers_list {
    overflow-y: scroll;
}

.customer {
    padding: 6px;
}
.active_customer {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}
</style>
